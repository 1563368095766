import Boy from '../../assets/images/boy.png';
import Girl from '../../assets/images/girl.png';

export const ORDER_STATS = ['待付款', '待参加', '已参加', '已过期'];

export const yesOrNo = [
    { value: 0, label: '否' },
    { value: 1, label: '是' },
];

export const MARRAY_ARRAY = {
    0: '单身',
    1: '恋爱中',
    2: '已婚',
    3: '离异',
    4: '再婚',
};
export const SEX_PICS_ARRAY = {
    0: Boy,
    1: Girl,
};
export const SALE_TYPE = [
    { value: 'normal', label: '单卖' },
    { value: 'pintuan', label: '拼团' },
    { value: 'all', label: '综合' },
];
export const ACTIVITY_STATUS = {
    0: '即将开始报名',
    1: '火热报名中',
    3: '报名结束',
    4: '活动进行中',
    5: '活动结束',
};

export const ORGANIZOR_ARRAY = [
    {
        name: '木马圈',
        phone: '18782291042',
        wechat: 'ly2315544',
    },
    {
        name: '李杨',
        phone: '18782291042',
        wechat: 'ly2315544',
    },
    {
        name: '尹润萍',
        phone: '18380463160',
        wechat: 'Rain_pia',
    },
    {
        name: '吴奇',
        phone: '632922',
        wechat: 'wuqiLan-evo',
    },
];

export const marraies = [
    { value: 1, label: '单身' },
    { value: 0, label: '无' },
];

export const cities = [{ value: '成都', label: '成都' }];

export const saleTypes = [
    { value: 1, label: '单卖' },
    { value: 2, label: '拼团' },
    { value: 3, label: '综合' },
];

export const activityTypes = [
    { value: 1, label: '线上' },
    { value: 2, label: '线下' },
];
