import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { dateTimeFormat } from '../../../utilities/constants/formats';
import { OutlineWrapper, PureError, PureDateTime } from '../../ui/form';

const DateInput = ({
    meta,
    label,
    input,
    mandatory,
    inputComponent: Input = PureDateTime,
    wrapperComponent: Wrapper = OutlineWrapper,
    errorComponent: Error = PureError,
    wrapperProps,
}) => {
    const { value, onChange: change } = input;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    const onChange = useCallback(date => change(format(date, dateTimeFormat)), [change]);

    return (
        <Wrapper label={label} mandatory={mandatory} meta={meta} name={input.name} {...wrapperProps}>
            <Input
                format="y-MM-dd h:mm"
                onChange={onChange}
                value={value && new Date(parseISO(value))}
                disableClock
            />
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
};

DateInput.propTypes = {
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    wrapperComponent: PropTypes.elementType,
    wrapperProps: PropTypes.shape({}),
};

const DateField = props => <Field component={DateInput} {...props} />;

export default DateField;
