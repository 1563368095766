import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const IconButton = ({ children, icon, size, ...props }) => (
    <button type="button" {...props}>
        {children || <Icon icon={icon} size={size} />}
    </button>
);

IconButton.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.shape({}),
    size: PropTypes.string,
};

export default styled(IconButton)`
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
