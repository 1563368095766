import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as yup from 'yup';
import { requiredString } from '../../../utilities/forms';
import GalleryContainer, { ImgDispaly, ImgItem, Title, DeleteBtn } from '../../ui/Gallery';
import { Upload } from '../form/FileField';

const Gallery = ({ change, values }) => {
    const headImg = get('head_img', values);
    const images = get('images', values) || [];
    const qrCode = get('qr_code', values);

    const deleteHeadImage = useCallback(() => change('head_img', null), [change]);
    const deleteImages = useCallback(
        item =>
            change(
                'images',
                images.filter(i => i !== item)
            ),
        [change, images]
    );
    const deleteQrCode = useCallback(() => change('qr_code', null), [change]);

    return (
        <GalleryContainer>
            <Upload change={change} values={values} />
            <Title>
                封面图 <span>(为了小程序美观，尺寸最好是：750*420)</span>
            </Title>
            {headImg && (
                <ImgDispaly>
                    <ImgItem>
                        <DeleteBtn onClick={deleteHeadImage}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </DeleteBtn>
                        <img alt="封面" src={headImg} />
                    </ImgItem>
                </ImgDispaly>
            )}

            <Title>
                Banner图 <span>(为了小程序美观，尺寸最好是：750*420)</span>
            </Title>
            {images && images.length > 0 && (
                <ImgDispaly>
                    {images.map((item, index) => (
                        <ImgItem key={index.toString()}>
                            <DeleteBtn onClick={() => deleteImages(item)}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </DeleteBtn>
                            <img alt={`banner ${index}`} src={item} />
                        </ImgItem>
                    ))}
                </ImgDispaly>
            )}
            <Title>二维码</Title>
            {qrCode && (
                <ImgDispaly>
                    <ImgItem>
                        <DeleteBtn onClick={deleteQrCode}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </DeleteBtn>
                        <img alt="二维码" src={qrCode} />
                    </ImgItem>
                </ImgDispaly>
            )}
        </GalleryContainer>
    );
};

Gallery.propTypes = {
    change: PropTypes.func.isRequired,
    values: PropTypes.shape({}),
};

export const schema = yup.object().shape({
    head_img: requiredString('请上传活动封面图'),
    images: requiredString('请上传活动Banner图'),
});

export default Gallery;
