import styled from 'styled-components';

const TabContainer = styled.div`
    margin: 0 20px 50px;

    .react-tabs__tab-list {
        .react-tabs__tab {
            height: 45px;
            line-height: 60px;
            font-size: 18px;
            border: 0;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.react-tabs__tab--selected {
                border-bottom: 3px solid #409eff;
            }
        }
    }
`;

export default TabContainer;
