import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px auto;
    width: 80%;
`;

export const Swiper = styled.div`
    margin: 20px auto;

    img {
        width: 100%;
    }
`;

export const Title = styled.div`
    font-size: 1.2rem;
    margin-top: 10px;
`;

export const Description = styled.div`
    padding: 20px 0 20px 0;
    color: #606266;
    font-size: 0.9rem;
`;

export const Line = styled.div`
    border-top: 1px solid #80808052;
    margin: 20px 0;
`;

export const Item = styled.div`
    font-size: 0.9rem;
    padding: 10px 0;
    color: #606266;
`;
