import styled from 'styled-components';
import { DefaultButton, PrimaryButton } from '../buttons';

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    align-items: center;
    cursor: initial;
`;

export const Column = styled.div`
    flex: 1;
    cursor: pointer;

    button {
        background: transparent;
        color: #409eff;
    }
`;

export const Info = styled.div`
    margin: 20px 0;
    svg {
        color: #606266;
    }

    span {
        padding-left: 10px;
        padding-right: 10px;
    }

    img {
        cursor: pointer;
        width: 150px;
        height: 100px;
    }

    ${PrimaryButton}, ${DefaultButton} {
        padding: 5px;
        margin: 5px 10px;
        font-size: 0.8rem;
        display: block;

        &:hover {
            background: transparent;
        }
    }

    ${DefaultButton} {
        color: #606266;
    }
`;
