import styled from 'styled-components';

export const Table = styled.div`
    margin-bottom: 20px;
    padding: 0 20px;
`;

export const Item = styled.div`
    cursor: pointer;
    border-bottom: 1px solid #ebeef5;
    display: flex;
    flex-direction: row;
    padding: 10px 0;

    &:hover {
        background: #f6faff;
    }
`;

export const Title = styled.div`
    margin: 20px 20px;
`;

export const PureItem = styled.div`
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    width: 100%;

    > div {
        flex: 1;
        font-size: 0.9rem;
    }
`;
