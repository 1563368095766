import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { upload } from '../../../actions';
import TextField from './TextField';

const RichEditorInput = ({ onChange: change, value: initValue }) => {
    const dispatch = useDispatch();

    const handleEditorChange = useCallback(content => change(content), [change]);

    const filePickerCallback = useCallback(
        cb => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = async event => {
                const file = event.target.files[0];

                const response = await dispatch(upload([file]));

                cb(response[0], { title: '' });
            };

            input.click();
        },
        [dispatch]
    );

    return (
        <Editor
            apiKey="4fauv7ainmhaefjhan70k0ucg2dynh0eylcrdstdaa38whx6"
            init={{
                height: 500,
                automatic_uploads: true,
                file_picker_callback: filePickerCallback,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                ],
                toolbar: `undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    image | \
                    bullist numlist outdent indent | removeformat | fullscreen | help`,
            }}
            initialValue={initValue}
            onEditorChange={handleEditorChange}
        />
    );
};

RichEditorInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

const RichEditor = props => <TextField inputComponent={RichEditorInput} {...props} />;

export default RichEditor;
