import styled from 'styled-components';

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: auto;
    height: 100%;
`;

export const Page = styled.div`
    background: #f0f0f0;
    flex: 1;
    overflow: auto;
    height: 100%;
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export default Wrapper;
