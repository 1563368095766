import PropTypes from 'prop-types';
import React from 'react';
import { Table, Item, PureItem } from '../../ui/Table';

const renderHeader = () => (
    <PureItem>
        <div>活动名称</div>
        <div>消费码</div>
        <div>参与时间</div>
    </PureItem>
);

const renderItem = item => (
    <PureItem>
        <div>{item.product_name}</div>
        <div>{item.order_no}</div>
        <div>{item.pay_time}</div>
    </PureItem>
);

const ActivityList = ({ items }) => {
    if (!items?.length) {
        // no data yet
        return null;
    }

    return (
        <Table>
            {renderHeader()}
            {items.map((item, index) => (
                <Item key={index.toString()}>{renderItem(item)}</Item>
            ))}
        </Table>
    );
};

ActivityList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

export default ActivityList;
