import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { getLoadingTasks } from '../../selectors';
import { spring } from '../animations/ZoomInAndOut';

const LoadingLayer = ({ className }) => {
    const loadingTasks = useSelector(getLoadingTasks);
    const hasTasks = loadingTasks.filter(task => !task.silent).length > 0;

    const transitions = useTransition(hasTasks, null, spring);

    return transitions.map(
        ({ item, key, props: { opacity, transform } }) =>
            item && (
                <animated.div
                    key={key}
                    className={className}
                    style={{
                        background: opacity.interpolate(value => `rgba(255, 255, 255, ${value * 0.1}`),
                    }}
                >
                    <animated.div style={{ transform, opacity }}>
                        <Icon color="black" icon={faSpinner} size="3x" spin />
                    </animated.div>
                </animated.div>
            )
    );
};

LoadingLayer.propTypes = {
    className: PropTypes.string.isRequired,
};

const StyledLoadingLayer = styled(LoadingLayer)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        width: 50px;
        height: 50px;
    }
`;

export default StyledLoadingLayer;
