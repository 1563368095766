import * as api from '../api';
import { attachLoading } from './loading';

export const getTemplates = (paging, search) => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchTemplates(GET, paging, search);

    return dispatch(attachLoading(promise));
};

export const getTemplate = id => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchTemplate(GET, id);

    return dispatch(attachLoading(promise));
};

export const createOrUpdateTemplate = (values, isPublish) => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;

    if (isPublish) {
        return dispatch(attachLoading(api.saveAndPublish(POST, values)));
    }

    return dispatch(attachLoading(api.saveTemplate(POST, values)));
};

export const deleteTemplate = id => (dispatch, getState, { httpMethods }) => {
    const { DELETE } = httpMethods;
    const promise = api.deleteTemplate(DELETE, id);

    return dispatch(attachLoading(promise));
};
