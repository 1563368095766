import styled from 'styled-components';
import IconButton from './IconButton';

export const Info = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
        color: #999;
        font-size: 1rem;
        padding-top: 10px;
    }
`;

export const Item = styled.div`
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    height: 100px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    margin: 3px 0;

    ${IconButton} {
        width: 100px;
        height: 100px;

        background: ${props => props.color};

        svg {
            font-size: 3.57rem;
            color: white;
        }
    }

    ${Info} {
        color: ${props => props.color};
        font-size: 1.28rem;
    }
`;

const DashboardContainer = styled.div`
    margin: 20px 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;

    @media (min-width: 320px) and (max-width: 739px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: 740px) and (max-width: 968px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export default DashboardContainer;
