import { GLOBAL_ERROR_SET, GLOBAL_ERROR_UNSET } from '../actions/globalErrors';

const reducer = (state = null, { type, ...payload }) => {
    switch (type) {
        case GLOBAL_ERROR_SET:
            return payload.error;

        case GLOBAL_ERROR_UNSET:
            return null;

        default:
            return state;
    }
};

export default reducer;
