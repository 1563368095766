import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';

const Actions = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: 20px;
    margin-left: -5px;
    margin-right: -5px;

    ${PrimaryButton} {
        flex: 1;
        font-size: 1.35rem;
        height: 45px;
        padding: 0;
        line-height: 0;
    }

    & > * {
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export default Actions;
