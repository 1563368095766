import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { submit } from 'redux-form';
import { updateActivityReview, getActivityReview } from '../../../actions';
import { handleResponseError } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import { TextButton, Actions } from '../../ui/buttons';
import FormActions from '../../ui/form/FormActions';
import Form from './Form';

const useReview = id => {
    const [review, setReview] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getActivityReview(id)).then(res => setReview(res));
        }
    }, [dispatch, id, setReview]);

    return review;
};

const ActivityReviewRoute = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const initialValues = useReview(id);

    const onSubmit = useCallback(values => dispatch(updateActivityReview(values)).catch(handleResponseError), [
        dispatch,
    ]);

    const onSubmitSuccess = useCallback(() => history.push('/home'), [history]);

    const save = useCallback(() => dispatch(submit('activityReview')), [dispatch]);

    if (!initialValues) {
        return null;
    }

    return (
        <PageContainer>
            <FormActions.Layout>
                <FormError form="activityReview">{error => <FormActions.Error>{error}</FormActions.Error>}</FormError>
            </FormActions.Layout>
            <TitleContainer>
                活动回顾
                <Actions>
                    <TextButton onClick={() => history.goBack()}>取消</TextButton>
                    <TextButton onClick={save}>提交修改</TextButton>
                </Actions>
            </TitleContainer>
            <Form initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} />
        </PageContainer>
    );
};

export default ActivityReviewRoute;
