import styled from 'styled-components';

export const UserImage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 20px;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
`;

export const SignTime = styled.div`
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0 10px;
`;

export const BaseInfo = styled.div`
    flex: 1;
    margin: 0 10px;

    > div {
        margin: 20px 0;
        display: flex;
        align-items: center;

        span {
            padding: 0 10px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
`;
