/* eslint-disable import/order */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-cropper';
import { useDispatch } from 'react-redux';
import { upload } from '../../../actions';
import { ButtonContainers } from '../../ui/Gallery';
import UploadButton from '../../ui/form/Upload';
import TextField from './TextField';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import lrz from 'lrz';

const dataURItoBlob = dataURI => {
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // mime类型
    const byteString = atob(dataURI.split(',')[1]); // base64 解码
    const arrayBuffer = new ArrayBuffer(byteString.length); // 创建缓冲数组
    const intArray = new Uint8Array(arrayBuffer); // 创建视图

    for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([intArray], { type: mimeString });
};

export const Upload = ({ change, values }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [cropper, setCropper] = useState();
    const images = get('images', values) || [];

    const onUpload = useCallback(
        async event => {
            const { value: type } = event.target;
            const results = await lrz(cropper.getCroppedCanvas().toDataURL(), { quality: 0.8 });
            const blobObject = dataURItoBlob(results.base64);
            blobObject.lastModifiedDate = new Date();
            blobObject.name = 'demo.png';

            try {
                const response = await dispatch(
                    upload([new File([blobObject], blobObject.name, { type: blobObject.type })])
                );

                if (response) {
                    if (type === 'head_img' || type === 'qr_code') {
                        change(type, response[0]);
                    } else {
                        change(type, (images || []).concat(response));
                    }
                    setCropper(null);
                    setImage(null);
                } else {
                    // eslint-disable-next-line no-alert
                    alert('图片上传失败！！！');
                }
            } catch (e) {
                // eslint-disable-next-line no-alert
                alert('图片上传失败！！！');
            }
        },
        [change, cropper, dispatch, images]
    );

    const onChange = useCallback(
        event => {
            event.preventDefault();
            let files;
            if (event.dataTransfer) {
                files = event.dataTransfer.files;
            } else if (event.target) {
                files = event.target.files;
            }

            if (files[0] && files[0].size / (1024 * 1024) > 5) {
                // eslint-disable-next-line no-alert
                alert('上传图片不能大于 5M 哟！！！');

                return;
            }

            if (files) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
                setFile('');
            }
        },
        [setFile]
    );

    return (
        <>
            {image && (
                <Cropper
                    // Cropper.js options
                    dragMode="move"
                    guides={false}
                    initialAspectRatio={750 / 420}
                    onInitialized={instance => {
                        setCropper(instance);
                    }}
                    scalable={false}
                    src={image}
                    style={{ height: 400, width: '100%' }}
                    viewMode={1}
                    zoomable={false}
                />
            )}
            <ButtonContainers>
                <UploadButton>
                    <label htmlFor="upload">打开图片</label>
                    <input
                        accept="image/png, image/jpeg, image/gif, image/jpg"
                        id="upload"
                        onChange={onChange}
                        type="file"
                        value={file}
                    />
                </UploadButton>
                <UploadButton disabled={!cropper} onClick={onUpload} value="head_img">
                    上传封面图
                </UploadButton>
                <UploadButton disabled={!cropper} onClick={onUpload} value="images">
                    上传Banner图
                </UploadButton>
                <UploadButton disabled={!cropper} onClick={onUpload} value="qr_code">
                    上传二维码
                </UploadButton>
            </ButtonContainers>
        </>
    );
};

Upload.propTypes = {
    change: PropTypes.func,
    values: PropTypes.shape({}),
};

const FileField = props => <TextField inputComponent={Upload} {...props} />;

export default FileField;
