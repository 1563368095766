import { orderBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, cloneElement } from 'react';
import { Field } from 'redux-form';
import { OutlineWrapper, PureError, PureSelect } from '../../ui/form';

const SelectInput = props => {
    const {
        input,
        meta,
        options,
        disabled = false,
        clearable = false,
        label,
        selectComponent: Select = PureSelect,
        wrapperComponent: Wrapper = OutlineWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        mandatory,
        noSort,
        onChangeEvent,
        ...selectProps
    } = props;

    const { value, onChange, name } = input;

    const handleChange = useCallback(
        option => {
            onChange(option.value);

            if (onChangeEvent) {
                onChangeEvent(option.value);
            }
        },
        [onChange, onChangeEvent]
    );
    const currentOption = useMemo(() => options.find(option => option.value === value), [value, options]) || null;

    // sort options (optional behavior)
    const cleanedOptions = useMemo(() => {
        if (noSort) {
            return options;
        }

        return orderBy(['label'], ['asc'], options);
    }, [noSort, options]);

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    return (
        <Wrapper label={label} mandatory={mandatory} meta={meta} name={name} {...wrapperProps}>
            {cloneElement(
                <Select
                    isClearable={clearable}
                    isDisabled={disabled}
                    name={name}
                    onChange={handleChange}
                    options={cleanedOptions}
                    value={currentOption}
                    {...selectProps}
                />,
                { hasError }
            )}
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
};

SelectInput.propTypes = {
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired,
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    noSort: PropTypes.bool,
    onChangeEvent: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        }).isRequired
    ).isRequired,
    selectComponent: PropTypes.elementType,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const SelectField = props => <Field {...props} component={SelectInput} />;

export default SelectField;
