import React from 'react';
import { OutlineWrapper, GroupField } from '../../ui/form';
import TextField from './TextField';

const MemberAge = () => (
    <OutlineWrapper label="活动年龄" mandatory>
        <GroupField>
            <TextField name="age_lower" type="number" />
            <span>-</span>
            <TextField name="age_upper" type="number" />
        </GroupField>
    </OutlineWrapper>
);

export default MemberAge;
