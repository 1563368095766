import PropTypes from 'prop-types';
import React from 'react';
import { Table, Item, PureItem } from '../../ui/Table';

const renderHeader = () => (
    <PureItem>
        <div>名字</div>
        <div>性别</div>
        <div>微信号</div>
        <div>手机号</div>
        <div>消费金额</div>
        <div>消费码</div>
        <div>消费时间</div>
    </PureItem>
);

const renderItem = item => (
    <PureItem>
        <div>{item.real_name || item.wechat_account}</div>
        <div>{item.gender ? '女' : '男'}</div>
        <div>{item.wechat_account}</div>
        <div>{item.phone}</div>
        <div>{item.pay_price}</div>
        <div>{item.order_no}</div>
        <div>{item.pay_time}</div>
    </PureItem>
);

const PartnerTable = ({ items }) => {
    if (!items?.length) {
        // no data yet
        return null;
    }

    return (
        <Table>
            {renderHeader()}
            {items.map((item, index) => (
                <Item key={index.toString()}>{renderItem(item)}</Item>
            ))}
        </Table>
    );
};

PartnerTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

export default PartnerTable;
