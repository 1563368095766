import { faDollarSign, faEuroSign, faUserFriends, faUniversalAccess } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStatistics } from '../../../actions';
import DashboardContainer, { Item, Info } from '../../ui/Dashboard';
import IconButton from '../../ui/IconButton';

const rows = [
    { color: '#2d8cf0', icon: faDollarSign, text: '今日收入', valueName: 'today_money' },
    { color: '#64d572', icon: faEuroSign, text: '总收入', valueName: 'total_money' },
    { color: '#c85e43', icon: faUniversalAccess, text: '今日新增人数', valueName: 'today_people' },
    { color: '#f25e9b', icon: faUserFriends, text: '总人数', valueName: 'total_people' },
];

const Dashboard = () => {
    const dispatch = useDispatch();
    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        dispatch(getStatistics()).then(setStatistics);
    }, [dispatch, setStatistics]);

    const renderBlock = item => (
        <Item key={item.color} color={item.color}>
            <IconButton icon={item.icon} />
            <Info>
                {statistics?.[item.valueName]} <span>{item.text}</span>
            </Info>
        </Item>
    );

    return <DashboardContainer>{rows.map(renderBlock)}</DashboardContainer>;
};

export default Dashboard;
