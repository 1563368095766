import React, { useCallback, useEffect } from 'react';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import { useHttp } from '../../utilities/HttpProvider';

const status = [
    ['UNREALIZED', '未实现'],
    ['REALIZED', '已实现'],
];

const inputStyle = {
    padding: '8px',
};

const formStyle = { display: 'flex', alignItems: 'center', gap: '16px', padding: '16px' };

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    // margin: '16px',
    // border: '1px solid #ccc',
    // '& td, & th': {
    //     border: '1px solid #ccc',
    //     padding: '8px',
    // },
};

const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #ccc',
};

function useRequest(request) {
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    // const [error, setError] = React.useState(null);

    const run = useCallback(
        param => {
            setLoading(true);
            request(param)
                .then(res => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    // setError(err);
                    setLoading(false);
                });
        },
        [request]
    );

    return { data, loading, run };
}

export default () => {
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [searchStr, setSearchStr] = React.useState('');
    const [condition, setCondition] = React.useState({ stat: '', queryStr: '', pageNum: 1, pageSize: 999 });
    const http = useHttp();
    const { data = [], run } = useRequest(
        useCallback(param => http.GET('/api/wish/list', null, { query: param }), [http])
    );

    const doSubmit = e => {
        e.preventDefault();
        const cond = { ...condition, stat: selectedStatus, queryStr: searchStr };
        setCondition(cond);
        run(cond);
    };

    const doSelect = e => {
        setSelectedStatus(e.target.value);
        const cond = { ...condition, stat: e.target.value };
        setCondition(cond);
        run(cond);
    };

    const setStat = (id, stat) => {
        http.POST('/api/wish/stat', {
            wish_id: id,
            stat,
        }).then(() => {
            run(condition);
        });
    };

    useEffect(() => {
        setTimeout(() => {
            run(condition);
        }, 20);
    }, []);

    return (
        <PageContainer>
            <TitleContainer>找搭子</TitleContainer>
            <form onSubmit={doSubmit} style={formStyle}>
                <select onChange={doSelect} style={inputStyle} value={selectedStatus}>
                    <option value="">全部</option>
                    {status.map(it => (
                        <option key={it} value={it[0]}>
                            {it[1]}
                        </option>
                    ))}
                </select>

                <input
                    onChange={e => setSearchStr(e.target.value)}
                    placeholder="模糊搜索"
                    style={inputStyle}
                    type="text"
                    value={searchStr}
                />
            </form>
            <div style={{ padding: '16px' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <td style={tdStyle}>时间</td>
                            <td style={tdStyle}>昵称</td>
                            <td style={tdStyle}>姓名</td>
                            <td style={tdStyle}>性别</td>
                            <td style={tdStyle}>年龄</td>
                            <td style={tdStyle}>职业</td>
                            <td style={tdStyle}>微信号</td>
                            <td style={tdStyle}>电话号码</td>
                            <td style={tdStyle}>需求</td>
                            <td style={tdStyle}>操作</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(d => (
                            <tr key={d.id}>
                                <td style={tdStyle}>{d.created_at}</td>
                                <td style={tdStyle}>{d.nickname}</td>
                                <td style={tdStyle}>{d.username}</td>
                                <td style={tdStyle}>{d.gender === 'MALE' ? '男' : '女'}</td>
                                <td style={tdStyle}>{d.age}</td>
                                <td style={tdStyle}>{d.jobs}</td>
                                <td style={tdStyle}>{d.wechat_id}</td>
                                <td style={tdStyle}>{d.cellphone}</td>
                                <td style={tdStyle}>{d.user_desc}</td>
                                <td style={tdStyle}>
                                    <select
                                        onChange={e => setStat(d.id, e.target.value)}
                                        style={inputStyle}
                                        value={d.stat}
                                    >
                                        {status.map(it => (
                                            <option key={it} value={it[0]}>
                                                {it[1]}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PageContainer>
    );
};
