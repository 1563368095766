import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import { Field } from 'redux-form';
import { OutlineWrapper, PureTextarea, PureError } from '../../ui/form';

export const TextareaInput = props => {
    const {
        input,
        meta,
        label,
        inputComponent: Input = PureTextarea,
        wrapperComponent: Wrapper = OutlineWrapper,
        errorComponent: Error = PureError,
        mandatory,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    return (
        <Wrapper label={label} mandatory={mandatory} meta={meta} name={input.name}>
            {cloneElement(<Input {...input} {...inputProps} cols={50} rows={4} />, { hasError })}
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
};

TextareaInput.propTypes = {
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    wrapperComponent: PropTypes.elementType,
};

const TextareaField = props => <Field component={TextareaInput} {...props} />;

export default TextareaField;
