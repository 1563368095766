import React from 'react';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import JoinedActivities from './JoinedActivities';
import UserInfo from './UserInfo';

const UsersRoute = () => {
    return (
        <PageContainer>
            <TitleContainer>用户详情</TitleContainer>
            <UserInfo />
            <JoinedActivities />
        </PageContainer>
    );
};

export default UsersRoute;
