import styled from 'styled-components';
import TextButton from './TextButton';

const Actions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
    margin-right: 20px;

    ${TextButton} {
        margin-right: 10px;
    }
`;

export default Actions;
