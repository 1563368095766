import styled from 'styled-components';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    min-height: 70px;
    font-size: 1.57rem;
    color: #fff;
    background-color: #242f42;
    padding: 0 30px;
`;

export default HeaderContainer;
