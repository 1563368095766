import * as api from '../api';
import { unsetContext } from './context';
import { attachLoading } from './loading';

export const AUTHORIZATION_SET = '@AUTHORIZATION/SET';

export const AUTHORIZATION_UNSET = '@AUTHORIZATION/UNSET';

export const setAuthorization = accessToken => ({
    type: AUTHORIZATION_SET,
    authorization: { accessToken },
});

export const unsetAuthorization = () => ({ type: AUTHORIZATION_UNSET });

export const authenticate = (username, password) => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.fetchAuthorization(POST, username, password);

    return dispatch(attachLoading(promise));
};

export const logout = () => (dispatch, getState, { history }) => {
    dispatch(unsetAuthorization());
    dispatch(unsetContext());
    history.push('/login');
};
