import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { QMap, Marker, utils, config } from 'react-tmap';
import styled from 'styled-components';
import PureInput from '../../ui/form/PureInput';

const Map = styled.div`
    height: 300px;
    position: relative;
`;
const KeyListContaner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background: white;
    color: black;
    max-height: 200px;
    overflow: auto;

    > div {
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
            background: hsl(0, 0%, 95%);
        }
    }
`;

const TencentMap = ({ value, setValue }) => {
    const center = { lat: value?.lat || 30.658698, lng: value?.lng || 104.065912 };

    const [searchValue, setSearchValue] = useState(null);

    const [keyList, setKeyList] = useState([]);
    const geocoder = useMemo(
        () =>
            new window.qq.maps.SearchService({
                complete: results => {
                    if (results.type === 'CITY_LIST') {
                        geocoder.setLocation(results.detail.cities[0].cityName);
                        geocoder.search(searchValue);

                        return;
                    }
                    const { pois } = results.detail;
                    setKeyList(pois);
                },
            }),
        [searchValue]
    );

    const markPosition = useCallback(
        (map, event) => {
            const lat = event.latLng.getLat();
            const lng = event.latLng.getLng();

            utils.getAddressByPosition({ lat, lng }).then(result => {
                const {
                    detail: { address },
                } = result;
                setValue({ name: address, lat, lng });
            });
        },
        [setValue]
    );

    const searchOnChange = useCallback(
        event => {
            const val = event.target.value;
            setSearchValue(val);
            geocoder.search(val);
        },
        [geocoder, setSearchValue]
    );

    const onSelect = useCallback(
        position => {
            setValue({ name: position.name, ...position.latLng });
            setSearchValue(position.name);
            setKeyList([]);
        },
        [setValue]
    );

    // close search list when click other space
    useEffect(() => {
        const closeSearch = () => {
            setKeyList([]);
        };
        document.addEventListener('click', closeSearch);

        return () => {
            document.removeEventListener('click', closeSearch);
        };
    }, [setKeyList]);

    return (
        <>
            <PureInput onChange={searchOnChange} placeholder="搜索" value={searchValue} />
            <Map>
                <KeyListContaner>
                    {keyList.map((item, index) => (
                        <div key={index.toString()} onClick={() => onSelect(item)}>
                            {item.name}
                        </div>
                    ))}
                </KeyListContaner>
                <QMap
                    center={center}
                    events={{
                        click: markPosition,
                    }}
                    style={{ height: '300px' }}
                    zoom={13}
                >
                    <Marker
                        animation={config.ANIMATION_DROP}
                        decoration="10"
                        // 标记提示文案
                        position={{ lat: value?.lat, lng: value?.lng }}
                        draggable
                        visible
                    />
                </QMap>
            </Map>
        </>
    );
};

TencentMap.propTypes = {
    setValue: PropTypes.func,
    value: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        name: PropTypes.string,
    }),
};

export default TencentMap;
