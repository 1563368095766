import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import LoginBg from '../../../assets/images/login-bg.jpg';

const LoginContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
`;

const LoginWrapper = ({ children }) => (
    <LoginContainer>
        <img alt="bg" src={LoginBg} />
        {children}
    </LoginContainer>
);
LoginWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};
export default LoginWrapper;
