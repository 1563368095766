import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

export const Tag = styled.span`
    background-color: rgba(64, 158, 255, 0.1);
    font-size: 12px;
    color: #409eff;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid rgba(64, 158, 255, 0.2);
    white-space: nowrap;
    height: 24px;
    padding: 3px 8px;
    line-height: 22px;
`;

export const SuccessTag = styled(Tag)`
    background-color: rgba(103, 194, 58, 0.1);
    border-color: rgba(103, 194, 58, 0.2);
    color: #67c23a;
`;

export const TagPageContainer = styled.div`
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const TagContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(64, 158, 255, 0.1);
    font-size: 12px;
    height: 32px;
    color: #409eff;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #409eff;
    white-space: nowrap;
    padding: 0 10px;
    line-height: 22px;
    margin: 10px;

    span {
        padding-left: 10px;
        cursor: pointer;
    }
`;

export const NewTagInput = styled.input`
    white-space: nowrap;
    line-height: 22px;
    margin: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    color: #000;
    font-size: 12px;
    border: 1px solid #000;
    padding: 0 10px;
    width: 80px;
    margin: 10px;
`;

export const NewTagContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    font-size: 12px;
    color: #000;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #000;
    white-space: nowrap;
    padding: 0px 10px;
    line-height: 22px;
    margin: 10px;
    cursor: pointer;

    input {
        height: 100%;
        border: 0;
    }

    span {
        padding-right: 10px;
        cursor: pointer;
    }
`;

export const NewTag = ({ onConfirm }) => {
    const [value, setValue] = useState('');
    const [showInput, setShowInput] = useState(false);

    const onBlur = useCallback(() => {
        setValue('');
        setShowInput(false);
    }, [setShowInput, setValue]);

    const onKeyUp = useCallback(
        event => {
            if (event.keyCode === 13) {
                onConfirm(event.target.value);
                setShowInput(false);
            }
        },
        [onConfirm]
    );

    return (
        <>
            {showInput && (
                <NewTagInput
                    maxLength={5}
                    onBlur={onBlur}
                    onChange={event => setValue(event.target.value)}
                    onKeyUp={onKeyUp}
                    value={value}
                    autoFocus
                />
            )}
            {!showInput && (
                <NewTagContainer onClick={() => setShowInput(true)}>
                    <span>+</span> 新建标签
                </NewTagContainer>
            )}
        </>
    );
};

NewTag.propTypes = {
    onConfirm: PropTypes.func.isRequired,
};

export const TagItem = ({ item, onRemove }) => (
    <TagContainer>
        {item}
        <span onClick={() => onRemove(item)}>X</span>
    </TagContainer>
);

TagItem.propTypes = {
    item: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};
