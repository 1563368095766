import React from 'react';
import ActivitiesRoute from '../ActivitiesRoute';
import Dashboard from './Dashboard';

const HomeRoute = () => (
    <>
        <Dashboard />
        <ActivitiesRoute />
    </>
);

export default HomeRoute;
