import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    outline: none;
    width: 100%;
    border: 1px solid #dcdfe6;
    color: #606266;
    background: transparent;
    height: 30px;
    font-size: 0.85rem;
    padding-left: 10px;
    border-radius: 3px;

    &:disabled {
        background: hsl(0, 0%, 95%);
    }
`;

const Container = styled.div`
    display: flex;
    align-items: stretch;
    width: 100%;
`;

const PureInput = ({ inputRef, ...props }) => (
    <Container>
        <Input {...props} ref={inputRef} />
    </Container>
);

PureInput.propTypes = {
    inputRef: PropTypes.shape({}),
};

export default PureInput;
