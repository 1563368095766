export const fetchTemplates = async (GET, paging, search) => {
    const query = { page_num: paging.page, page_size: paging.pageSize, query: search };
    const { data } = await GET('/api/templates', null, { query });

    return data;
};

export const fetchTemplate = async (GET, id) => {
    const { data } = await GET(`/api/templates/${id}/info`);

    return data;
};

export const saveTemplate = (POST, params) => POST(`/api/templates`, params);

export const deleteTemplate = (POST, id) => POST(`/api/templates/${id}/del`, {});

export const saveAndPublish = (POST, params) => POST(`/api/templates/activity`, params);
