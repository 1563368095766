import * as api from '../api';
import { attachLoading } from './loading';

// eslint-disable-next-line import/prefer-default-export
export const getStatistics = () => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchStatistics(GET);

    return dispatch(attachLoading(promise));
};

export const upload = files => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.upload(POST, files);

    return dispatch(attachLoading(promise));
};

export const getTags = (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchTags(GET);

    return dispatch(attachLoading(promise));
};

export const updateTags = values => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.updateTags(POST, {
        type: 'character',
        tags: values,
    });

    return dispatch(attachLoading(promise));
};
