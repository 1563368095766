import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getActivity } from '../../../actions';
import PageContainer, { TitleContainer, Navigation } from '../../ui/PageContainer';
import Partners from './Partners';
import { ReactComponent as Back } from '../../../assets/images/back.svg';

const ActivityViewRoute = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [result, setResult] = useState(null);

    useEffect(() => {
        dispatch(getActivity(id)).then(setResult);
    }, [dispatch, id, setResult]);

    const onBack = useCallback(() => history.goBack(), [history]);

    if (!result) {
        return null;
    }

    return (
        <PageContainer>
            <TitleContainer>
                <Navigation onClick={onBack}>
                    <Back />
                    <span>活动详情</span>
                </Navigation>
            </TitleContainer>
            <Partners />
        </PageContainer>
    );
};

export default ActivityViewRoute;
