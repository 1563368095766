import { LOADING_REGISTER, LOADING_UNREGISTER } from '../actions/loading';

const reducer = (state = [], { type, ...payload }) => {
    switch (type) {
        case LOADING_REGISTER:
            return [...state, payload.task];

        case LOADING_UNREGISTER:
            return state.filter(task => task.id !== payload.taskId);

        default:
            return state;
    }
};

export default reducer;
