import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { yesOrNo } from '../../../../utilities/constants/options';
import DateTimeField from '../../../shared/form/DateTimeField';
import SelectField from '../../../shared/form/SelectField';
import Grid from '../../../ui/Grid';
import { useFormContext } from './context';

const Publish = () => {
    const { values, change } = useFormContext();

    const isAuto = get('is_auto', values);
    useEffect(() => {
        if (!isAuto) {
            change('auto_publish_time', null);
        }
    }, [change, isAuto]);

    return (
        <Grid>
            <SelectField label="定时发布" name="is_auto" options={yesOrNo} />
            {!!isAuto && <DateTimeField label="发布时间" name="auto_publish_time" />}
        </Grid>
    );
};
export default Publish;
