import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { reduxForm, getFormValues } from 'redux-form';
import { createFormValidation } from '../../../../utilities/forms';
import RichEditor from '../../../shared/form/RichEditor';
import Activity, { schema as baseSchema } from '../../../shared/partialForms/Activity';
import Gallery, { schema as gallerySchema } from '../../../shared/partialForms/Gallery';
import Organization from '../../../shared/partialForms/Organization';
import TabContainer from '../../../ui/Tab';
import { TextButton, FloatActions } from '../../../ui/buttons';
import FormContainer from '../../../ui/form/FormContainer';
import Publish from './Publish';
import { Context } from './context';

const TemplateForm = props => {
    const { form, change, handleSubmit, onApply } = props;
    const values = useSelector(getFormValues(form));

    const context = { ...props, values };

    const onAction = useCallback(event => handleSubmit(value => onApply(value, event.target.value))(event), [
        handleSubmit,
        onApply,
    ]);

    return (
        <Context.Provider value={context}>
            <FormContainer>
                <FloatActions>
                    <TextButton onClick={onAction} type="button" value="save">
                        保存
                    </TextButton>
                    <TextButton onClick={onAction} type="button" value="publish">
                        保存并发布
                    </TextButton>
                </FloatActions>
                <TabContainer>
                    <Tabs forceRenderTabPanel>
                        <TabList>
                            <Tab>基本信息</Tab>
                            <Tab>发布者信息</Tab>
                            <Tab>图片</Tab>
                            <Tab>图文</Tab>
                            <Tab>发布设置</Tab>
                        </TabList>
                        <TabPanel>
                            <Activity change={change} values={values} />
                        </TabPanel>
                        <TabPanel>
                            <Organization change={change} />
                        </TabPanel>
                        <TabPanel>
                            <Gallery change={change} values={values} />
                        </TabPanel>
                        <TabPanel>
                            <RichEditor name="detail" />
                        </TabPanel>
                        <TabPanel>
                            <Publish />
                        </TabPanel>
                    </Tabs>
                </TabContainer>
            </FormContainer>
        </Context.Provider>
    );
};

TemplateForm.propTypes = {
    change: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'template', validate: createFormValidation(baseSchema.concat(gallerySchema)) })(
    TemplateForm
);
