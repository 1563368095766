import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Input = styled.input`
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    flex-grow: 2;
    color: #000;
    margin: 0;
    padding: 10px 15px;
    box-shadow: none;
    box-sizing: border-box;
    outline: none;

    ${props =>
        props.hasError &&
        css`
            border-color: #c10034;
        `}

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: stretch;
    width: 100%;
`;

const OutlineInput = ({ suffixElement, ...props }) => (
    <Container>
        <Input {...props} />
        {suffixElement || null}
    </Container>
);

OutlineInput.propTypes = {
    suffixElement: PropTypes.element,
};

export default OutlineInput;
