// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// Sentry.init({
//     dsn: 'https://be7acda4c29f4300b8303a18f8c14b61@o484049.ingest.sentry.io/5536811',
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 0.5,
// });

ReactDOM.render(<App />, document.getElementById('root'));
