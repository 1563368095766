import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { ORGANIZOR_ARRAY } from '../../../utilities/constants/options';
import Grid from '../../ui/Grid';
import SelectField from '../form/SelectField';
import TextField from '../form/TextField';

const Organization = ({
    change,
    contactNameField = 'contact_name',
    contactPhoneField = 'contact_phone',
    contactWechat = 'contact_wechat',
}) => {
    const onChange = useCallback(
        value => {
            const find = ORGANIZOR_ARRAY.find(item => item.name === value);
            change('contact_phone', find.phone);
            change('contact_wechat', find.wechat);
        },
        [change]
    );

    return (
        <>
            <Grid>
                <SelectField
                    label="发起人"
                    name={contactNameField}
                    onChangeEvent={onChange}
                    options={ORGANIZOR_ARRAY.map(item => ({ value: item.name, label: item.name }))}
                    mandatory
                    noSort
                />
            </Grid>
            <Grid>
                <TextField label="电话" name={contactPhoneField} mandatory />
            </Grid>
            <Grid>
                <TextField label="微信" name={contactWechat} mandatory />
            </Grid>
        </>
    );
};

Organization.propTypes = {
    change: PropTypes.func.isRequired,
    contactNameField: PropTypes.string,
    contactPhoneField: PropTypes.string,
    contactWechat: PropTypes.string,
};

export default Organization;
