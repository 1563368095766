import { format, subMinutes } from 'date-fns';
import { dateTimeFormat } from './constants/formats';

const initTemplate = {
    // 置顶
    is_top: 0,
    start_time: format(new Date(`${format(new Date(), 'MMM dd,yyyy')} 14:30:00`), dateTimeFormat),
    end_time: format(new Date(`${format(new Date(), 'MMM dd,yyyy')} 21:00:00`), dateTimeFormat),
    apply_start_time: format(new Date(), dateTimeFormat),
    apply_end_time: format(
        new Date(subMinutes(new Date(`${format(new Date(), 'MMM dd,yyyy')} 21:00:00`), 1)),
        dateTimeFormat
    ),

    // only 0 is allowed
    is_group: 0,
    activity_type: 1,
    sale_price: 0,
    origin_price: 0,
    is_single: 1,
    city: '成都',
    male_ratio: 1,
    female_ratio: 1,
    age_lower: 25,
    age_upper: 35,
    apply_min_limit: 10,
    apply_limit: 20,
    join_type: 1,
    longitude: null,
    latitude: null,
    addresses: '线上',

    head_img: null,
    qr_code: null,
    images: null,

    // 发布人信息
    contact_name: '木马圈',
    contact_phone: '18782291042',
    contact_wechat: 'ly2315544',

    is_auto: 0,
    auto_publish_time: null,
};

export default initTemplate;
