import styled from 'styled-components';

const GalleryContainer = styled.div``;

export const ButtonContainers = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Title = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    span {
        font-size: 11px;
        color: red;
    }
`;

export const ImgDispaly = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ImgItem = styled.div`
    position: relative;
    width: 150px;
    height: 150px;
    margin: 10px;
    word-wrap: break-word;

    img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
`;

export const DeleteBtn = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 25px;
    color: grey;
    cursor: pointer;
`;

export default GalleryContainer;
