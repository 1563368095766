import styled, { css } from 'styled-components';

export const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    color: #9c9c9c;
    height: 33px;
    line-height: 33px;
    font-size: 0.72rem;
    margin: 0 20px;
`;

export const Text = styled.span`
    flex-grow: 2;
`;

export const Pages = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Page = styled.button`
    background: white;
    text-transform: ${props => props.theme.casing};
    outline: none;
    border: 1px solid #ced4da;
    border-radius: 3px;
    cursor: pointer;
    padding: 0 10px;
    color: #9c9c9c;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
        props.active &&
        css`
            color: #000000;
        `}

    &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: 0;
    }

    &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 1px solid #ced4da;
    }
`;
