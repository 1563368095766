import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { memoize } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as UI from '../ui/Pagination';

const getPages = (currentPage, maxPage) => {
    // put our current page first in the list
    const pages = [currentPage];

    do {
        // get the index for the next page
        const nextPage = currentPage + pages.length;

        if (nextPage > maxPage) {
            // we reached the latest page
            break;
        }

        // append it to our list
        pages.push(nextPage);
    } while (pages.length < 3);

    while (pages.length < 3) {
        // get the index for the previous page
        const previousPage = pages[0] - 1;

        if (previousPage <= 0) {
            // we reached the first page
            break;
        }

        // prepend it to our list
        pages.unshift(previousPage);
    }

    return pages;
};

const Pagination = ({ paging, records = 0 }) => {
    // extract data from paging
    const { page, pageSize, setPage } = paging;

    const goTo = useMemo(() => {
        return memoize(index => () => setPage(index));
    }, [setPage]);

    if (!records) {
        // there is no pagination to display
        return null;
    }

    // extract data fron results
    const pages = records % pageSize === 0 ? records / pageSize : records / pageSize + 1;

    // compute information
    const firstItem = (page - 1) * pageSize + 1;
    const lastNum = Math.min(firstItem + pageSize - 1, records);

    return (
        <UI.Pagination>
            <UI.Text>
                Showing&nbsp;{firstItem}&nbsp;to&nbsp;{lastNum}&nbsp;of&nbsp;{records}&nbsp;entries
            </UI.Text>
            {pages >= 2 && (
                <UI.Pages>
                    <UI.Page onClick={goTo(1)}>First</UI.Page>
                    <UI.Page onClick={goTo(Math.max(1, page - 1))}>
                        <Icon icon={faArrowLeft} />
                    </UI.Page>
                    {getPages(page, pages).map(pageIndex => (
                        <UI.Page key={pageIndex} active={page === pageIndex} onClick={goTo(pageIndex)}>
                            {pageIndex}
                        </UI.Page>
                    ))}
                    <UI.Page onClick={goTo(Math.min(pages, page + 1))}>
                        <Icon icon={faArrowRight} />
                    </UI.Page>
                    <UI.Page onClick={goTo(pages)}>Last</UI.Page>
                </UI.Pages>
            )}
        </UI.Pagination>
    );
};

Pagination.propTypes = {
    paging: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        setPage: PropTypes.func.isRequired,
    }).isRequired,
    records: PropTypes.number.isRequired,
};

export default Pagination;
