// eslint-disable-next-line import/prefer-default-export
export const fetchStatistics = async GET => {
    const { data } = await GET('/api/home/statistics/total');

    return data;
};

export const upload = async (POST, formData) => {
    const body = new FormData();
    Object.entries(formData).forEach(([key, value]) => value && body.append(key, value));

    const { data } = await POST('/api/img', body);

    return data;
};

export const fetchTags = async GET => {
    const { data } = await GET('/api/tags?type=character');

    return data;
};

export const updateTags = (POST, params) => POST(`/api/tags`, params);
