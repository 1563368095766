export const fetchActivities = async (GET, paging, search) => {
    const query = { page_num: paging.page, page_size: paging.pageSize, query: search };
    const { data } = await GET('/api/activity', null, { query });

    return data;
};

export const fetchActivity = async (GET, id) => {
    const { data } = await GET(`/api/activity/${id}/info`);

    return data;
};

export const updateActivity = (POST, id, params) => POST(`/api/activity/${id}/info`, params);

export const updateActivityReview = (POST, id, params) => POST(`/api/activity/${id}/review`, params);

export const fetchActivityReview = async (GET, id) => {
    const { data } = await GET(`/api/activity/${id}/review`);

    return data;
};

export const onlineActivity = async (POST, id, saleStatus) =>
    POST(`/api/activity/${id}/salestatus`, { id, sale_status: saleStatus ? 'off' : 'on' });

export const onTopActivity = async (POST, id, isTop) =>
    POST(`/api/activity/${id}/top`, { id, top_status: isTop ? 'off' : 'on' });

export const fetchPartners = async (GET, id, paging, search) => {
    const query = { page_num: paging.page, page_size: paging.pageSize, query: search };
    const { data } = await GET(`/api/activity/${id}/partners`, null, { query });

    return data;
};
