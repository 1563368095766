import React from 'react';
import { OutlineWrapper, GroupField } from '../../ui/form';
import TextField from './TextField';

const Price = () => (
    <OutlineWrapper>
        <GroupField>
            <TextField label="活动价钱" name="sale_price" type="number" mandatory />
            <span />
            <TextField label="划线价" name="origin_price" type="number" mandatory />
        </GroupField>
    </OutlineWrapper>
);

export default Price;
