import React from 'react';
import { reduxForm } from 'redux-form';
import RichEditor from '../../shared/form/RichEditor';
import TextField from '../../shared/form/TextField';
import Grid from '../../ui/Grid';

const ActivityReviewForm = () => {
    return (
        <>
            <Grid.One>
                <TextField label="活动名称" name="name" placeholder="请输入活动名称" disabled />
            </Grid.One>
            <Grid.One>
                <RichEditor name="detail" />
            </Grid.One>
        </>
    );
};

export default reduxForm({ form: 'activityReview' })(ActivityReviewForm);
