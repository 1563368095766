import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTags, updateTags } from '../../../actions/miscellaneous';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import { TagItem, TagPageContainer, NewTag } from '../../ui/Tag';

const TagRoute = () => {
    const dispatch = useDispatch();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        dispatch(getTags).then(setTags);
    }, [dispatch]);

    const onRemove = useCallback(
        async item => {
            await dispatch(updateTags(tags.filter(it => it !== item)));
            dispatch(getTags).then(res => setTags(res));
        },
        [dispatch, tags, setTags]
    );

    const onConfirm = useCallback(
        async value => {
            const find = tags.find(item => item === value);
            if (find) {
                return null;
            }

            const newTags = [...tags];
            newTags.unshift(value);
            await dispatch(updateTags(newTags));
            dispatch(getTags).then(res => setTags(res));

            return null;
        },
        [tags, dispatch]
    );

    return (
        <PageContainer>
            <TitleContainer>标签管理</TitleContainer>
            <TagPageContainer>
                <NewTag onConfirm={onConfirm} />
                {tags.map((item, index) => (
                    <TagItem key={index.toString()} item={item} onRemove={onRemove} />
                ))}
            </TagPageContainer>
        </PageContainer>
    );
};

export default TagRoute;
