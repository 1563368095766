import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getUser } from '../../../actions';
import { MARRAY_ARRAY, SEX_PICS_ARRAY } from '../../../utilities/constants/options';
import UserInfoContainer, { AvatorContainer } from '../../ui/user/UserInfo';
import { BaseInfo } from '../../ui/user/UserItem';

const UserInfo = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [result, setResult] = useState(null);

    useEffect(() => {
        dispatch(getUser(id)).then(setResult);
    }, [dispatch, id, setResult]);

    if (!result) {
        return null;
    }

    return (
        <UserInfoContainer>
            <AvatorContainer>
                <img alt="" src={result.user_icon} />
            </AvatorContainer>
            <BaseInfo>
                <div>
                    <span>{result.nickname || result.real_name}</span>
                    <img alt="" src={SEX_PICS_ARRAY[result.gender]} />
                    <span>{MARRAY_ARRAY[result.marital_status]}</span>
                    <span>{result.birthday}</span>
                </div>
                <div>
                    <span>T: {result.phone}</span> <span>微信: {result.wechat_account}</span>
                    <span>
                        {result.province}
                        {result.city}
                    </span>
                    <span>{result.jobs}</span>
                </div>
                <div>
                    <span>注册时间: {result.create_time}</span>
                </div>
                <div>
                    <span>个性签名: {result.self_desc}</span>
                </div>
            </BaseInfo>
        </UserInfoContainer>
    );
};

export default UserInfo;
