import { isEmpty, fromPairs, toPairs, flow, filter } from 'lodash/fp';
import qs from 'qs';
import { setGlobalError, logout } from '../actions';
import { getAccessToken } from '../selectors';
import { BadGateway, BadRequest, InternalError, PermissionDenied, Unauthorized } from './HttpErrors';
import { parseJsonFromResponse, writeAuthorizationHeader } from './httpUtilities';

const defaultOptions = {
    // prefix to prepend to urls
    urlPrefix: null,
    // default headers
    headers: {},
    // query arguments
    query: {},
};

const mergeOptions = (options, customOptions) => ({
    ...options,
    ...customOptions,
    headers: {
        ...options?.headers,
        ...customOptions?.headers,
    },
    query: {
        ...options?.query,
        ...customOptions?.query,
    },
});

const cleanQuery = flow([toPairs, filter(([, value]) => value !== null && value !== undefined), fromPairs]);

const handleResponse = async response => {
    switch (response.status) {
        case 400:
            return BadRequest.parseResponse(response);

        case 401:
            return Unauthorized.parseResponse(response);

        case 403:
            return PermissionDenied.parseResponse(response);

        case 500:
            return InternalError.parseResponse(response);

        case 502:
            return BadGateway.parseResponse(response);

        case 200:
        default: {
            const cleanedResponse = await parseJsonFromResponse(response);

            if (response.ok) {
                switch (cleanedResponse.code) {
                    case 400:
                        return Promise.reject(cleanedResponse);

                    case 401:
                        return Unauthorized.parseResponse(response);

                    case 403:
                        return Promise.reject(cleanedResponse);

                    case 500:
                        return InternalError.parseResponse(response);

                    case 502:
                        return BadGateway.parseResponse(response);

                    case 0:
                        return cleanedResponse;
                    default: {
                        return Promise.reject(cleanedResponse);
                    }
                }
            }

            return Promise.reject(cleanedResponse);
        }
    }
};

const createFetch = (method, customMethodOptions = {}) => {
    const methodOptions = mergeOptions(defaultOptions, customMethodOptions);

    // custom fetch function
    const customFetch = async (url, body = null, customQueryOptions = {}) => {
        // get final options
        const options = mergeOptions(methodOptions, customQueryOptions);
        // extract whatever we need
        const { urlPrefix, headers: defaultHeaders, query } = options;

        // copy headers to avoid mutations
        const headers = { ...defaultHeaders };
        // build fetch options object
        const fetchOptions = { method, headers };

        if (body && body instanceof FormData) {
            // we are using a form data, the native fetch API will handle it
            fetchOptions.body = body;
        } else if (body) {
            // convert to json request
            headers['Content-Type'] = 'application/json';
            fetchOptions.body = JSON.stringify(body);
        }

        // get the final URL
        let cleanedUrl = url.startsWith('/') && urlPrefix ? `${urlPrefix}${url}` : url;

        const cleanedQuery = cleanQuery(query);
        if (!isEmpty(cleanedQuery)) {
            cleanedUrl = `${cleanedUrl}${qs.stringify(cleanedQuery, { addQueryPrefix: true })}`;
        }

        // finally call fetch
        return fetch(cleanedUrl, fetchOptions).then(handleResponse, handleResponse);
    };

    // entry point to override the method options
    customFetch.override = customOptions => createFetch(method, mergeOptions(methodOptions, customOptions));

    return customFetch;
};

export const wrapHttpMethod = (customFetch, wrapper) => {
    // wrap the custom fetch function itself with our wrapper
    // it should be working as a middleware does
    const newCustomFetch = wrapper(customFetch);

    // wrap every time there is an override
    newCustomFetch.override = customOptions => wrapHttpMethod(customFetch.override(customOptions), wrapper);

    return newCustomFetch;
};

export const withAuthorization = getState => customFetch => (url, body, options) => {
    // get the redux state
    const state = getState();
    // retrieve the access token
    const accessToken = getAccessToken(state);

    if (!accessToken) {
        // we have nothing to do about it
        return customFetch(url, body, options);
    }

    // add the authorization header
    const headers = { Authorization: writeAuthorizationHeader(accessToken) };

    // finally override options on the call
    return customFetch(url, body, mergeOptions(options, { headers }));
};

export const withLogout = dispatch => customFetch => (...args) =>
    customFetch(...args).catch(response => {
        if (response instanceof Unauthorized || response instanceof PermissionDenied) {
            // update redux state
            dispatch(logout());
            // inform loading it should be muted
            response.muteOnloading = true;
        }

        return Promise.reject(response);
    });

export const withGlobalErrors = dispatch => customFetch => (...args) =>
    customFetch(...args).catch(response => {
        if (response instanceof BadGateway || response instanceof InternalError) {
            // could be useful to print it in console
            console.error(response);
            // push it into redux (warning: this cannot be stringify)
            dispatch(setGlobalError(response));
            // inform loading it should be muted
            response.muteOnloading = true;
        }

        return Promise.reject(response);
    });

export const GET = createFetch('get');
export const POST = createFetch('post');
export const PUT = createFetch('PUT');
export const DELETE = createFetch('delete');
