import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';
import { createFormValidation, requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import PasswordField from '../../shared/form/PasswordField';
import TextField from '../../shared/form/TextField';
import { PrimaryButton, Actions } from '../../ui/buttons';
import FormActions from '../../ui/form/FormActions';
import FormContainer from '../../ui/login/FormContainer';
import Title from '../../ui/login/Title';

const CredentialForm = ({ handleSubmit }) => {
    return (
        <FormContainer onSubmit={handleSubmit}>
            <Title>木马圈后台管理系统</Title>
            <TextField.Outline label="Username" name="username" placeholder="Username" autoFocus />
            <PasswordField label="Password" name="password" placeholder="Password" />
            <FormActions>
                <Actions>
                    <PrimaryButton type="submit">Sign In</PrimaryButton>
                </Actions>
                <FormError form="loginCredentials">{error => <FormActions.Error>{error}</FormActions.Error>}</FormError>
            </FormActions>
        </FormContainer>
    );
};

CredentialForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const schema = yup.object().shape({
    password: requiredString('Password is mandatory'),
    username: requiredString('Username is mandatory'),
});

export default reduxForm({
    form: 'loginCredentials',
    validate: createFormValidation(schema),
})(CredentialForm);
