import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import ActivityReviewRoute from '../routes/ActivityReviewRoute';
import ActivityRoute from '../routes/ActivityRoute';
import ActivityViewRoute from '../routes/ActivityViewRoute';
import HomeRoute from '../routes/HomeRoute';
import JoinRoute from '../routes/JoinRoute/index';
import TagRoute from '../routes/TagRoute';
import TemplateRoute from '../routes/TemplateRoute';
import TemplatesRoute from '../routes/TemplatesRoute';
import UserRoute from '../routes/UserRoute';
import UsersRoute from '../routes/UsersRoute/index';

import Header from '../shared/Header';
import Navigator from '../shared/Navigator';
import Wrapper, { Content, Page } from '../ui/Wrapper';

const MainRouter = () => (
    <Wrapper>
        <Header />
        <Content>
            <Navigator />
            <Page>
                <Switch>
                    <Route component={HomeRoute} path="/home" exact />
                    <Route component={UsersRoute} path="/user" exact />
                    <Route component={UserRoute} path="/user/:id" />
                    <Route component={ActivityViewRoute} path="/activity/:id" exact />
                    <Route component={ActivityReviewRoute} path="/activity/review/:id" exact />
                    <Route component={ActivityRoute} path="/activity/edit/:id" exact />
                    <Route component={TemplatesRoute} path="/template" exact />
                    <Route component={TemplateRoute} path="/template/new" />
                    <Route component={TemplateRoute} path="/template/:id" />
                    <Route component={TagRoute} path="/tags" exact />
                    <Route component={JoinRoute} path="/join" exact />
                    <Redirect to="/home" />
                </Switch>
            </Page>
        </Content>
    </Wrapper>
);
export default MainRouter;
