import styled from 'styled-components';

const TextButton = styled.button`
    text-transform: ${props => props.theme.casing};
    outline: none;
    height: 32px;
    font-size: 0.72rem;
    border: 0;
    color: white;
    background: transparent;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    background: #409eff;
    cursor: pointer;
`;

export default TextButton;
