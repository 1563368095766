import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import IconButton from './IconButton';

const Container = styled.form`
    width: 200px;
    height: 30px;
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 0 10px;

    & > ${IconButton} {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            color: #999;
            font-size: 1.28rem;
        }
    }
`;

const Input = styled.input`
    color: #606266;
    flex-grow: 2;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 1rem;
    min-width: 0;
`;

const SearchInput = ({ onSubmit, inputRef, onInputChange, value }) => (
    <Container onSubmit={onSubmit}>
        <Input ref={inputRef} onChange={onInputChange} placeholder="Search..." type="text" value={value} autoFocus />
        <IconButton icon={faSearch} type="submit" />
    </Container>
);

SearchInput.propTypes = {
    inputRef: PropTypes.shape({}).isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default SearchInput;
