import { createBrowserHistory } from 'history';
import { mapValues } from 'lodash/fp';
import React, { PureComponent } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { compose } from 'redux';
import { unsetGlobalError } from '../actions';
import createStore from '../createStore';
import { DELETE, GET, POST, PUT, withAuthorization, withGlobalErrors, withLogout } from '../utilities/httpMethods';
import AppRouter from './routers';
import RootErrorBoundary from './shared/errors/RootErrorBoundary';
import GlobalStyle from './ui/GlobalStyle';
import LoadingLayer from './ui/LoadingLayer';
import HttpProvider from './utilities/HttpProvider';

class App extends PureComponent {
    constructor(props) {
        super(props);

        // first we create the history object
        this.history = createBrowserHistory();

        // we are going to memoize an empty http method object
        this.httpMethods = {};

        // create the redux store
        this.store = createStore({ history: this.history, httpMethods: this.httpMethods });

        // then initialize the http methods
        this.initializeHttpMethods();

        this.history.listen(() => {
            // whenever th page change we may unset any global error we had
            this.store.dispatch(unsetGlobalError());
        });
    }

    initializeHttpMethods() {
        const { store, httpMethods } = this;

        // list methods
        const methods = { GET, POST, DELETE, PUT };

        // anonymous versions
        httpMethods.anonymous = { ...methods };

        // we are going to apply multiple middleware on authorized methods
        const composeAuthorizedMethods = compose(
            withAuthorization(store.getState),
            withLogout(store.dispatch),
            withGlobalErrors(store.dispatch)
        );

        // authorized versions (with bearer token)
        httpMethods.authorized = mapValues(composeAuthorizedMethods, methods);

        // give a direct access to methods as authorized
        Object.entries(httpMethods.authorized).forEach(([key, method]) => {
            httpMethods[key] = method;
        });
    }

    render() {
        const { store, httpMethods, history } = this;

        return (
            <StoreProvider store={store}>
                <HttpProvider value={httpMethods}>
                    <BrowserRouter history={history}>
                        <RootErrorBoundary>
                            <GlobalStyle />
                            <LoadingLayer />
                            <AppRouter />
                        </RootErrorBoundary>
                    </BrowserRouter>
                </HttpProvider>
            </StoreProvider>
        );
    }
}

export default App;
