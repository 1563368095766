import * as api from '../api';
import { attachLoading } from './loading';

export const getUsers = (paging, search) => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchUsers(GET, paging, search);

    return dispatch(attachLoading(promise));
};

export const getUser = id => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchUser(GET, id);

    return dispatch(attachLoading(promise));
};

export const getJoinedActivities = (id, paging) => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchJoinedActivities(GET, id, paging);

    return dispatch(attachLoading(promise));
};
