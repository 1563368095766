import styled from 'styled-components';

const Title = styled.div`
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #fff;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
`;

export default Title;
