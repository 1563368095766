import { useState, useMemo } from 'react';

const usePaging = (initialPage = 1, pageSize = 10) => {
    // for paging the API is begins the readying with page 1 (not zero)
    const [page, setPage] = useState(initialPage);

    return useMemo(() => ({ page, setPage, pageSize }), [page, setPage, pageSize]);
};

export default usePaging;
