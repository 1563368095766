import styled from 'styled-components';

export const TitleContainer = styled.div`
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-radius: 3px;
    color: #606266;
    border-bottom: 1px solid #ebeef5;
    font-size: 1.3rem;
`;

export const Navigation = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
        width: 20px;
        position: relative;
        top: -1px;
    }
`;

const PageContainer = styled.div`
    border-radius: 3px;
    background: white;
    margin: 10px;
    color: #606266;
    padding-bottom: 20px;
`;

export default PageContainer;
