import { CONTEXT_SET_USER, CONTEXT_UNSET } from '../actions/context';
import persistState from './persistState';

const initialState = {
    user: null,
};

const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case CONTEXT_SET_USER:
            return { ...state, user: payload.user };
        case CONTEXT_UNSET:
            return {};
        default:
            return state;
    }
};

export default persistState('context', reducer);
