import PropTypes from 'prop-types';
import React from 'react';
import { Table, Item } from '../../ui/Table';
import UserItem from './UserItem';

const UserList = ({ items, onItemClick }) => {
    if (!items?.length) {
        // no data yet
        return null;
    }

    return (
        <Table>
            {items.map(item => (
                <Item key={item.id} onClick={onItemClick && (() => onItemClick(item))}>
                    <UserItem item={item} />
                </Item>
            ))}
        </Table>
    );
};

UserList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    onItemClick: PropTypes.func.isRequired,
};

export default UserList;
