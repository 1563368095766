import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authorizationReducer from './authorization';
import contextReducer from './context';
import globalErrorsReducer from './globalErrors';
import loadingReducer from './loading';

const rootReducer = combineReducers({
    form: formReducer,
    authorization: authorizationReducer,
    context: contextReducer,
    globalErrors: globalErrorsReducer,
    loading: loadingReducer,
});

export default rootReducer;
