import styled from 'styled-components';

const PureTextarea = styled.textarea`
    color: #606266;
    outline: none;
    border: 1px solid #ced4da;
    padding: 10px;
    font-size: 0.85rem;
    width: calc(100% - 20px);
    min-height: 40px;
    border-radius: 3px;
`;

export default PureTextarea;
