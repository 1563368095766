import { subMinutes, format, isAfter, parseISO } from 'date-fns';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { getEnums } from '../../../actions';
import { dateTimeFormat } from '../../../utilities/constants/formats';
import { marraies, cities, saleTypes } from '../../../utilities/constants/options';
import { requiredString } from '../../../utilities/forms';
import Grid from '../../ui/Grid';
import ActivityMember from '../form/ActivityMember';
import DateTimeField from '../form/DateTimeField';
import MemberAge from '../form/MemberAge';
import Position from '../form/Position';
import Price from '../form/Price';
import SelectField from '../form/SelectField';
import TencentMap from '../form/TencentMap';
import TextField from '../form/TextField';
import TextareaField from '../form/TextareaField';

const Activity = ({ change, values }) => {
    const dispatch = useDispatch();
    const joinType = get('join_type', values);
    const addresses = get('addresses', values);
    const longitude = get('longitude', values);
    const latitude = get('latitude', values);

    const endTime = get('end_time', values);

    const baiDu = { name: addresses, lng: longitude, lat: latitude };

    const [tagMarks, setTagMarks] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);
    const setAddress = useCallback(
        value => {
            change('addresses', value.name);
            change('longitude', value.lng);
            change('latitude', value.lat);
        },
        [change]
    );

    useEffect(() => {
        dispatch(getEnums()).then(data => {
            setTagMarks(
                (data?.activity?.tag_mark?.values || []).map(it => ({ label: it.name, value: it.code }))
            );
            setActivityTypes(
                (data?.activity?.join_type?.values || []).map(it => ({ label: it.name, value: it.code }))
            );
        });
    }, []);

    const onJoinTypeChange = useCallback(
        value => {
            if (value === 1) {
                change('latitude', null);
                change('longitude', null);
                change('addresses', '线上');
            } else if (value === 2) {
                change('addresses', '');
            }
        },
        [change]
    );

    return (
        <>
            <Grid>
                <TextField label="活动名称" name="name" placeholder="请输入活动名称" mandatory />
                <TextareaField label="摘要" name="description" placeholder="请输入活动摘要" mandatory />
            </Grid>
            <Grid>
                <SelectField label="活动分类" name="tag_mark" options={tagMarks} mandatory />
            </Grid>
            <Grid>
                <DateTimeField label="活动开始时间" name="start_time" mandatory />
                <DateTimeField label="活动结束时间" name="end_time" mandatory />
                <DateTimeField label="报名开始时间" name="apply_start_time" mandatory />
                <DateTimeField label="报名结束时间" name="apply_end_time" mandatory />
            </Grid>
            <Grid>
                <ActivityMember />
                <MemberAge />
            </Grid>
            <Grid>
                <div>
                    <SelectField label="情感限制" name="is_single" options={marraies} mandatory />
                    <SelectField label="地域限制" name="city" options={cities} disabled />
                </div>
                <div>
                    <SelectField label="售卖形式" name="activity_type" options={saleTypes} disabled />
                    <Price />
                </div>
            </Grid>
            <Grid>
                <div>
                    <SelectField
                        label="活动形式"
                        name="join_type"
                        onChangeEvent={onJoinTypeChange}
                        options={activityTypes}
                        mandatory
                    />
                    {joinType === 'LIVE' && (
                        <TextField
                            id="mapSearch"
                            label="活动地址"
                            name="addresses"
                            placeholder="请在地图里边选择活动地址"
                            disabled
                        />
                    )}
                </div>
                {joinType === 'LIVE' && (
                    <div>
                        <Position />
                        <TencentMap setValue={setAddress} value={baiDu} />
                    </div>
                )}
            </Grid>
        </>
    );
};

Activity.propTypes = {
    change: PropTypes.func.isRequired,
    values: PropTypes.shape({}),
};

export const schema = yup.object().shape({
    addresses: yup.lazy((values, options) => {
        const { parent } = options;
        // eslint-disable-next-line camelcase
        const { join_type } = parent;

        // eslint-disable-next-line camelcase
        if (join_type === 2) {
            return requiredString('请选择地址');
        }

        return yup.string().nullable();
    }),
    apply_end_time: yup.lazy((values, options) => {
        const { parent } = options;
        const { apply_start_time: applyStartTime } = parent;

        return yup
            .string()
            .required('请输入报名结束时间')
            .test(
                'invalid-end-time',
                '活动报名结束时间不能小于于活动报名开始时间',
                value => !isAfter(parseISO(applyStartTime), parseISO(value))
            );
    }),
    apply_start_time: yup.lazy((values, options) => {
        const { parent } = options;
        const { apply_end_time: applyEndTime } = parent;

        return yup
            .string()
            .required('请选择报名开始时间')
            .test(
                'invalid-start-time',
                '活动报名开始时间不能大于活动报名结束时间',
                value => !isAfter(parseISO(value), parseISO(applyEndTime))
            );
    }),
    description: requiredString('请输入活动摘要'),
    end_time: yup.lazy((values, options) => {
        const { parent } = options;
        const { start_time: startTime } = parent;

        return yup
            .string()
            .required('请选择活动结束时间')
            .test(
                'invalid-end-time',
                '活动结束时间不能小于于活动开始时间',
                value => !isAfter(parseISO(startTime), parseISO(value))
            );
    }),
    name: requiredString('请输入活动名称'),
    start_time: yup.lazy((values, options) => {
        const { parent } = options;
        const { end_time: endTime } = parent;

        return yup
            .string()
            .required('请选择活动开始时间')
            .test(
                'invalid-start-time',
                '活动开始时间不能大于活动结束时间',
                value => !isAfter(parseISO(value), parseISO(endTime))
            );
    }),
});

export default Activity;
