import styled from 'styled-components';

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.93rem;
    cursor: pointer;

    img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 5px;
    }

    span {
        margin-left: 10px;
    }
`;

export default ProfileContainer;
