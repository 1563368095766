import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { authenticate, setAuthorization, loadUser, setUser } from '../../../actions';
import { handleResponseError } from '../../../utilities/forms';
import LoginWrapper from '../../ui/login/LoginWrapper';
import CredentialForm from './CredentialForm';

const LoginRoute = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // the callback to execute on submissions
    const onSubmit = useCallback(
        ({ username, password }) => dispatch(authenticate(username, password)).catch(handleResponseError),
        [dispatch]
    );

    // the callback to execute on successful submissions
    const onSubmitSuccess = useCallback(
        async authorization => {
            if (authorization) {
                const { token } = authorization;

                const user = await dispatch(loadUser(token));

                dispatch(setUser(user));
                // persist authorization
                dispatch(setAuthorization(token));
                // and finally redirect

                history.push('/home');
            }
        },
        [dispatch, history]
    );

    return (
        <LoginWrapper>
            <CredentialForm onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} />
        </LoginWrapper>
    );
};

export default LoginRoute;
