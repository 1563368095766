import styled from 'styled-components';

const FormContainer = styled.form`
    width: 350px;
    background: hsla(0, 0%, 100%, 0.3);
    z-index: 1;
    padding: 30px;
    border-radius: 3px;
`;

export default FormContainer;
