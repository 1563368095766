import styled from 'styled-components';

const UploadButton = styled.button`
    cursor: pointer;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    margin: 10px;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #409eff;
    color: #ffffff;
    border: 0;
    border-radius: 3px;
    word-break: keep-all;
    height: 33px;
    min-width: 100px;

    &:disabled {
        background: #666666;
        cursor: not-allowed;
    }

    label {
        cursor: pointer;
        color: #ffffff;
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input {
        display: none;
    }
`;

export default UploadButton;
