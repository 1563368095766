import * as api from '../api';
import * as selectors from '../selectors';
import { attachLoading } from './loading';

export const CONTEXT_UNSET = '@CONTEXT/UNSET';

export const unsetContext = () => ({ type: CONTEXT_UNSET });

export const CONTEXT_SET_USER = '@CONTEXT/SET_USER';

export const setUser = user => ({ type: CONTEXT_SET_USER, user });

export const loadUser = (accessToken = null) => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;

    // we are going to use the token from the state if none is not provided
    const token = accessToken ?? selectors.getAccessToken(getState());

    if (!token) {
        // nothing for us to do
        return Promise.resolve(null);
    }

    // fetch from the API
    const promise = api.fetchCurrentUser(GET, token);

    return dispatch(attachLoading(promise));
};
