import React from 'react';
import { OutlineWrapper, GroupField } from '../../ui/form';
import TextField from './TextField';

const ActivityMember = () => (
    <OutlineWrapper label="活动人数" mandatory>
        <GroupField>
            <TextField name="apply_min_limit" type="number" />
            <span>-</span>
            <TextField name="apply_limit" type="number" />
        </GroupField>
    </OutlineWrapper>
);

export default ActivityMember;
