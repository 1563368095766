import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MenuItem = styled(NavLink)`
    display: block;
    color: white;
    text-decoration: none;
    padding: 20px 30px;
    cursor: pointer;

    ${props =>
        props.sub === 'true' &&
        css`
            padding-left: 60px;
        `}

    button {
        width: 30px;

        svg {
            color: #909399;
            width: 22px;
        }
    }

    &.active {
        background-color: #242f42;
    }
`;

const NavigatorContainer = styled.div`
    width: 200px;
    min-width: 200px;
    color: #fff;
    background-color: rgb(50, 65, 87);
    color: rgb(191, 203, 217);
    padding-top: 20px;
`;

export default NavigatorContainer;
