import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { createOrUpdateTemplate, getTemplate } from '../../../actions';
import { handleResponseError } from '../../../utilities/forms';
import initTemplate from '../../../utilities/initTemplate';
import FormError from '../../shared/form/FormError';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import FormActions from '../../ui/form/FormActions';
import Form from './form';

const useTemplate = id => {
    const [template, setTemplate] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getTemplate(id)).then(setTemplate);
        }
    }, [dispatch, id, setTemplate]);

    return template;
};

const TemplateRoute = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const template = useTemplate(id);
    const initialValues = useMemo(() => (id ? template : initTemplate), [id, template]);

    const onApply = useCallback(
        (values, type) =>
            dispatch(createOrUpdateTemplate(values, type === 'publish'))
                .then(() => history.push('/template'))
                .catch(handleResponseError),
        [dispatch, history]
    );

    if (!initialValues) {
        return null;
    }

    return (
        <PageContainer>
            <FormActions.Layout>
                <FormError form="template">{error => <FormActions.Error>{error}</FormActions.Error>}</FormError>
            </FormActions.Layout>
            <TitleContainer>素材表单</TitleContainer>
            <Form initialValues={initialValues} onApply={onApply} />
        </PageContainer>
    );
};

export default TemplateRoute;
