import * as api from '../api';
import { attachLoading } from './loading';

export const getActivities = (paging, search) => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchActivities(GET, paging, search);

    return dispatch(attachLoading(promise));
};

export const getActivity = id => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchActivity(GET, id);

    return dispatch(attachLoading(promise));
};

export const updateActivity = values => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.updateActivity(POST, values?.id, values);

    return dispatch(attachLoading(promise));
};

export const updateOnlineActivity = (id, saleStatus) => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.onlineActivity(POST, id, saleStatus);

    return dispatch(attachLoading(promise));
};

export const updateOnTopActivity = (id, isTop) => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.onTopActivity(POST, id, isTop);

    return dispatch(attachLoading(promise));
};

export const getPartners = (id, paging, search) => (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const promise = api.fetchPartners(GET, id, paging, search);

    return dispatch(attachLoading(promise));
};

export const updateActivityReview = values => (dispatch, getState, { httpMethods }) => {
    const { POST } = httpMethods;
    const promise = api.updateActivityReview(POST, values?.activity_id, values);

    return dispatch(attachLoading(promise));
};

export const getActivityReview = id => async (dispatch, getState, { httpMethods }) => {
    const { GET } = httpMethods;
    const activity = await api.fetchActivity(GET, id);
    const review = await api.fetchActivityReview(GET, id);

    return {
        activity_id: activity.id,
        name: activity.name,
        detail: review?.detail,
    };
};
