import styled from 'styled-components';

const Button = styled.button`
    display: inline-block;
    outline: none;
    box-shadow: none;
    padding: 10px 15px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 3px;
    text-transform: ${props => props.theme.casing};

    transition: color 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;

    &:hover,
    &:active {
        transition: color 0.1s ease-in-out, background 0.1s ease-in-out, border 0.1s ease-in-out;
    }

    &:disabled {
        color: #c0c4cc;
        cursor: default;
        opacity: 0.65;
    }
`;

export default Button;
