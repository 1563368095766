import { set, get } from 'lodash/fp';
import { SubmissionError } from 'redux-form';
import * as yup from 'yup';
import { BadRequest } from './HttpErrors';

export const requiredString = msg => yup.string().nullable().required(msg);
export const requiredArray = msg => yup.array().nullable().required(msg);
export const requiredNumber = msg => yup.number().nullable().required(msg);
export const requiredBool = msg => yup.bool().nullable().required(msg);

export const createFormValidation = schema => (values, props) => {
    try {
        schema.validateSync(values, { abortEarly: false, context: props });
    } catch (validationError) {
        if (validationError instanceof yup.ValidationError) {
            return validationError.inner.reduce((errors, error) => {
                const {
                    path,
                    errors: [message],
                } = error;

                if (get(path, errors)) {
                    // do not override errors, only keep the first
                    return errors;
                }

                return set(path, message, errors);
            }, {});
        }

        throw validationError;
    }

    return null;
};

export const fromContextValidation = (instance, validationKey, errorMessage) =>
    yup.lazy((values, options) => {
        const validation = get(['context', 'validation', validationKey], options);

        if (!validation) {
            // nothing to test yet
            return instance;
        }

        return instance.test('match-validation', errorMessage, value => validation.test(value));
    });

export const handleResponseError = error => {
    if (error instanceof BadRequest) {
        throw new SubmissionError({ _error: error.message });
    }

    if (error.code !== 0) {
        throw new SubmissionError({ _error: error.msg });
    }

    // print ouf the error
    console.error(error);

    throw new SubmissionError({ _error: 'Something wrong happened' });
};
