import React from 'react';
import { OutlineWrapper, GroupField } from '../../ui/form';
import TextField from './TextField';

const Position = () => (
    <OutlineWrapper label="经度纬度" mandatory>
        <GroupField>
            <TextField name="longitude" disabled />
            <span>AND</span>
            <TextField name="latitude" disabled />
        </GroupField>
    </OutlineWrapper>
);

export default Position;
