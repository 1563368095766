import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { unsetAuthorization } from '../../actions';
import { getIsAuthenticated } from '../../selectors';
import LoginRoute from '../routes/LoginRoute';
import MainRouter from './MainRouter';

const AppRouter = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthenticated) {
            // when accessing app for the first time
            // clear context once if not yet authenticated
            dispatch(unsetAuthorization());
        }
    }, [dispatch, isAuthenticated]);

    return (
        <Switch>
            {!isAuthenticated && <Route component={LoginRoute} path="/login" exact />}
            {isAuthenticated && <Route component={MainRouter} exact />}
            <Redirect to="/login" />
        </Switch>
    );
};

export default AppRouter;
