import styled from 'styled-components';

export const CardContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;

    @media (min-width: 320px) and (max-width: 739px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: 740px) and (max-width: 968px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const TemplateCard = styled.div`
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    /* width: 280px; */
    margin: 20px;

    .display-image {
        width: 100%;
        height: 150px;
    }
    .title {
        font-size: 16px;
        color: #000;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .updated-time,
    .summary {
        margin: 10px 0;
        font-size: 13px;
        color: #606266;
    }
    .summary {
        min-height: 54px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .bottom-button {
        display: flex;
        flex-direction: row;
        button {
            flex: 1;
            margin: 0 10px;
        }
    }
`;

export default TemplateCard;
