import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions';
import ProfileImage from '../../assets/images/profile.jpg';
import { getUser } from '../../selectors';
import HeaderContainer from '../ui/header/HeaderContainer';
import ProfileContainer from '../ui/header/ProfileContainer';

const Header = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const signOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <HeaderContainer>
            木马圈后台管理系
            <ProfileContainer>
                <img alt="" src={ProfileImage} />
                {user?.user_name}
                <span onClick={signOut}>退出</span>
            </ProfileContainer>
        </HeaderContainer>
    );
};

export default Header;
