import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getActivities } from '../../../actions';
import Pagination from '../../shared/Pagination';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import SearchInput from '../../ui/SearchInput';
import { TextButton, Actions } from '../../ui/buttons';
import usePaging from '../../utilities/usePaging';
import ActivitiesList from './ActivitiesList';

const ActivitiesRoute = () => {
    const dispatch = useDispatch();
    const [result, setResult] = useState(null);
    const paging = usePaging(1, 5);

    const inputRef = useRef(null);
    const [value, setValue] = useState('');
    const { onInputChange, onSubmit } = useMemo(
        () => ({
            onInputChange: () => setValue(inputRef.current.value),
            onSubmit: event => {
                event.preventDefault();
                dispatch(getActivities(paging, value)).then(setResult);

                return false;
            },
        }),
        [dispatch, paging, value]
    );

    useEffect(() => {
        dispatch(getActivities(paging)).then(setResult);
    }, [dispatch, paging, setResult]);

    const refresh = useCallback(() => {
        dispatch(getActivities(paging)).then(setResult);
    }, [dispatch, paging, setResult]);

    const history = useHistory();
    const createTemplate = useCallback(() => {
        history.push(`/template/new`);
    }, [history]);

    if (!result) {
        return null;
    }

    return (
        <PageContainer>
            <TitleContainer>
                已发布活动
                <Actions>
                    <SearchInput inputRef={inputRef} onInputChange={onInputChange} onSubmit={onSubmit} value={value} />
                    <TextButton onClick={createTemplate}>新建素材</TextButton>
                </Actions>
            </TitleContainer>
            <ActivitiesList items={result?.data} refresh={refresh} />
            <Pagination paging={paging} records={result?.total || 0} />
        </PageContainer>
    );
};

export default ActivitiesRoute;
