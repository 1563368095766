import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: provided => ({
        ...provided,
        cursor: 'pointer',
        outline: 'none',
        boxShadow: 'none',
        border: '1px solid #dcdfe6',
        // background: '#f0f0f0',
        height: '32px',
        minHeight: '30px',
        borderRadius: '3px',
    }),
    menu: provided => ({
        ...provided,
        top: '32px',
        left: '0',
        right: '0',
        marginTop: 0,
        marginBottom: 0,
        width: 'auto',
        border: '1px solid #ced4da',
        borderRadius: '0',
        boxShadow: 'none',
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, { isSelected }) => ({
        ...provided,
        cursor: 'pointer',
        minHeight: '38px',
        lineHeight: '1',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        backgroundColor: 'transparent',
        color: isSelected ? '#409eff' : '#606266',
        ':hover': {
            ...provided[':hover'],
            color: '#409eff',
        },
        ':active': {
            ...provided[':active'],
            color: '#409eff',
        },
    }),
    dropdownIndicator: provided => ({ ...provided, padding: '0 5px 0 0' }),
    indicatorSeparator: () => ({ display: 'none' }),
    input: provided => ({ ...provided, margin: 0 }),
    valueContainer: provided => ({
        ...provided,
        fontSize: '0.85rem',
        paddingLeft: '0 0 0 10px',
    }),
    singleValue: provided => ({
        ...provided,
        marginLeft: 0,
        marginRight: 0,
        top: 'unset',
        transform: 'none',
    }),
};

const PureSelect = props => <Select placeholder="" {...props} menuPlacement="auto" styles={customStyles} />;

export default PureSelect;
