import DateTimePicker from 'react-datetime-picker';
import styled from 'styled-components';

const PureDateTime = styled(DateTimePicker)`
    width: 100%;

    .react-datetime-picker__wrapper {
        border-radius: 3px;
        height: 34px;
        border: 1px solid #dcdfe6;
        padding-left: 5px;
    }

    svg,
    span,
    select,
    option,
    input {
        color: #606266;
    }
`;

export default PureDateTime;
