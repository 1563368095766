import { faEye, faShare, faDollarSign, faUniversalAccess } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { updateOnlineActivity, updateOnTopActivity } from '../../../actions';
import { dateFormat } from '../../../utilities/constants/formats';
import { ACTIVITY_STATUS } from '../../../utilities/constants/options';
import IconButton from '../../ui/IconButton';
import { Table, Item } from '../../ui/Table';
import { SuccessTag, Tag } from '../../ui/Tag';
import { Row, Column, Info } from '../../ui/activity';
import { Button, DefaultButton, PrimaryButton } from '../../ui/buttons';

const ActivityItem = ({ item, refresh }) => {
    const dispatch = useDispatch();

    const online = useCallback(async () => {
        await dispatch(updateOnlineActivity(item.id, item.sale_status));
        refresh();
    }, [item, dispatch, refresh]);

    const onTop = useCallback(async () => {
        await dispatch(updateOnTopActivity(item.id, item.is_top));
        refresh();
    }, [item, dispatch, refresh]);

    const history = useHistory();
    const view = useCallback(() => history.push(`/activity/${item.id}`), [history, item]);

    return (
        <Row>
            <Column>
                <Info>
                    <span>{format(new Date(parseISO(item.start_time)), dateFormat)}</span>
                    <Tag>{ACTIVITY_STATUS[item.status]}</Tag>
                    <DefaultButton onClick={() => history.push(`activity/review/${item.id}`)}>活动回顾</DefaultButton>
                    {!item.qr_code && (
                        <PrimaryButton onClick={() => history.push(`activity/edit/${item.id}`)}>
                            上传二维码
                        </PrimaryButton>
                    )}
                </Info>
            </Column>
            <Column onClick={view}>
                <Info>
                    <img alt="" src={item.head_img} />
                </Info>
            </Column>
            <Column onClick={view}>
                <Info>
                    <span>{item.activity_name}</span>
                    {!!item.is_top && <SuccessTag>置顶</SuccessTag>}
                </Info>
                <Info>
                    <span>
                        <IconButton icon={faEye} /> 0
                    </span>
                    <span>
                        <IconButton icon={faShare} /> 0
                    </span>
                    <span>
                        <IconButton icon={faDollarSign} /> {item.income}
                    </span>
                    <span>
                        <IconButton icon={faUniversalAccess} /> {item.order_count || 0}
                    </span>
                </Info>
            </Column>
            <Column>
                <Button disabled={item.status === 5} onClick={() => history.push(`activity/edit/${item.id}`)}>
                    编辑
                </Button>
                <Button disabled={item.status === 5} onClick={onTop}>
                    {item.is_top ? '取消置顶' : '置顶'}
                </Button>
                <Button onClick={online}>{item.sale_status ? '下线' : '上线'}</Button>
            </Column>
        </Row>
    );
};

ActivityItem.propTypes = {
    item: PropTypes.shape({
        activity_name: PropTypes.string.isRequired,
        head_img: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        income: PropTypes.number.isRequired,
        is_top: PropTypes.number.isRequired,
        order_count: PropTypes.number.isRequired,
        qr_code: PropTypes.string.isRequired,
        sale_status: PropTypes.number.isRequired,
        start_time: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
    }),
    refresh: PropTypes.func.isRequired,
};

const ActivitiesList = ({ items, refresh }) => {
    if (!items?.length) {
        // no data yet
        return null;
    }

    return (
        <Table>
            {items.map(item => (
                <Item key={item.id}>
                    <ActivityItem item={item} refresh={refresh} />
                </Item>
            ))}
        </Table>
    );
};

ActivitiesList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    refresh: PropTypes.func.isRequired,
};

export default ActivitiesList;
