import PropTypes from 'prop-types';
import React from 'react';
import { MARRAY_ARRAY, SEX_PICS_ARRAY } from '../../../utilities/constants/options';
import { UserImage, SignTime, BaseInfo } from '../../ui/user/UserItem';

const renderBaseInfo = item => (
    <BaseInfo>
        <div>
            <span>{item.nickname || item.real_name}</span>
            <img alt="" src={SEX_PICS_ARRAY[item.gender]} />
            <span>{MARRAY_ARRAY[item.marital_status]}</span>
            <span>{item.birthday}</span>
        </div>
        <div>
            <span>T: {item.phone}</span> <span>微信: {item.wechat_account}</span>
            <span>
                {item.province}
                {item.city}
            </span>
            <span>{item.jobs}</span>
        </div>
        <div>
            <span>参与活动: {item.act_cnt}</span>
        </div>
    </BaseInfo>
);

const UserItem = ({ item }) => (
    <>
        <UserImage>
            <img alt="" src={item.user_icon} />
        </UserImage>
        {renderBaseInfo(item)}
        <SignTime>注册时间: {item.create_time}</SignTime>
    </>
);

UserItem.propTypes = {
    item: PropTypes.shape({
        create_time: PropTypes.string.isRequired,
        user_icon: PropTypes.string.isRequired,
    }),
};

export default UserItem;
