import { writeAuthorizationHeader } from '../utilities/httpUtilities';

export const fetchAuthorization = async (POST, username, password) => {
    const body = { username, password };

    const { data } = await POST('/api/login', body);

    return data;
};

export const fetchCurrentUser = async (GET, accessToken) => {
    const headers = { Authorization: writeAuthorizationHeader(accessToken) };
    const { data } = await GET('/api/me', null, { headers });

    return data;
};

export const getProfile = () => {
    return fetch(`/api/me`, {
        method: 'get',
    });
};
