import { faTasks, faHome, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useCallback } from 'react';
import IconButton from '../ui/IconButton';

import NavigatorContainer, { MenuItem } from '../ui/navigator/NavigatorContainer';

const MENU = [
    {
        id: 1,
        key: 'home',
        link: '/home',
        icon: faHome,
        label: '首页',
    },
    {
        id: 2,
        key: 'manage',
        link: '/manage',
        icon: faTasks,
        label: '管理',
        open: true,
        subMenu: [
            {
                id: 1,
                key: 'user',
                link: '/user',
                label: '用户管理',
            },
            {
                id: 2,
                key: 'template',
                link: '/template',
                label: '素材管理',
            },
            {
                id: 3,
                key: 'tag',
                link: '/tags',
                label: '标签管理',
            },
            {
                id: 4,
                key: 'join',
                link: '/join',
                label: '找搭子',
            },
        ],
    },
];

const Navigator = () => {
    const [menu, setMenu] = useState(MENU);

    const collapse = useCallback(
        (item, event) => {
            if (item.subMenu) {
                event.stopPropagation();
                event.preventDefault();
                setMenu(state => state.map(i => ({ ...i, open: i.id === item.id ? !i.open : i.open })));
            }
        },
        [setMenu]
    );

    const isActive = useCallback((match, location, item) => {
        if (!match) {
            return false;
        }

        return (match && match.isExact) || location?.pathname?.indexOf(item.link) !== -1;
    }, []);

    const renderNav = (item, isSub) => (
        <MenuItem
            key={item.key}
            isActive={(match, location) => isActive(match, location, item)}
            onClick={event => collapse(item, event)}
            sub={isSub ? 'true' : 'false'}
            to={item.link}
        >
            {item.icon && <IconButton icon={item.icon} />}
            {item.label}
            {item.subMenu && <IconButton icon={item.open ? faChevronUp : faChevronDown} style={{ float: 'right' }} />}
        </MenuItem>
    );

    const renderMenu = item => (
        <div key={item.key}>
            {renderNav(item, false)}
            {item.open && item.subMenu && item.subMenu.map(i => renderNav(i, true))}
        </div>
    );

    return <NavigatorContainer>{menu.map(renderMenu)}</NavigatorContainer>;
};

export default Navigator;
