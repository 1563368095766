import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { reduxForm, getFormValues } from 'redux-form';
import { createFormValidation } from '../../../utilities/forms';
import RichEditor from '../../shared/form/RichEditor';
import Activity, { schema } from '../../shared/partialForms/Activity';
import Gallery from '../../shared/partialForms/Gallery';
import Organization from '../../shared/partialForms/Organization';
import TabContainer from '../../ui/Tab';
import { Context } from './context';

const ActivityForm = props => {
    const { form, change } = props;
    const values = useSelector(getFormValues(form));

    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <TabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>基本信息</Tab>
                        <Tab>发布者信息</Tab>
                        <Tab>图片</Tab>
                        <Tab>图文</Tab>
                    </TabList>
                    <TabPanel>
                        <Activity change={change} values={values} />
                    </TabPanel>
                    <TabPanel>
                        <Organization
                            change={change}
                            contactNameField="sponsor_name"
                            contactPhoneField="sponsor_phone"
                            contactWechat="sponsor_wechat_account"
                        />
                    </TabPanel>
                    <TabPanel>
                        <Gallery change={change} values={values} />
                    </TabPanel>
                    <TabPanel>
                        <RichEditor name="detail" />
                    </TabPanel>
                </Tabs>
            </TabContainer>
        </Context.Provider>
    );
};

ActivityForm.propTypes = {
    change: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default reduxForm({ form: 'activity', validate: createFormValidation(schema) })(ActivityForm);
