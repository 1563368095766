import styled from 'styled-components';

const GroupField = styled.div`
    display: flex;
    flex-direction: row;

    & > div {
        flex: 1;
    }

    & > span {
        height: 30px;
        width: 30px;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export default GroupField;
