import styled from 'styled-components';

export const AvatorContainer = styled.div`
    width: 100px;
    padding-right: 20px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 20%;
    }
`;

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
`;

export default UserInfoContainer;
