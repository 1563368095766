import { v4 as uuidv4 } from 'uuid';

export const LOADING_REGISTER = '@LOADING/REGISTER';

export const LOADING_UNREGISTER = '@LOADING/UNREGISTER';

export const registerLoading = (taskId, silent = false) => ({
    type: LOADING_REGISTER,
    task: { id: taskId, silent },
});

export const unregisterLoading = taskId => ({
    type: LOADING_UNREGISTER,
    taskId,
});

export const attachLoading = (promise, silent = false) => dispatch => {
    // get the task id
    const taskId = uuidv4();

    // register the task
    dispatch(registerLoading(taskId, silent));

    // listen onto it
    return promise.then(
        resolved => {
            // stop the loading
            dispatch(unregisterLoading(taskId));

            return resolved;
        },
        // eslint-disable-next-line consistent-return
        error => {
            // stop the promise
            dispatch(unregisterLoading(taskId));

            if (!error || !error.muteOnloading) {
                // we should'nt mute this error
                return Promise.reject(error);
            }
        }
    );
};
