import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormError } from 'redux-form';

const FormError = ({ form, children }) => {
    const error = useSelector(getFormError(form));

    console.log(error)

    return error ? children(error) : null;
};

FormError.propTypes = {
    children: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default FormError;
