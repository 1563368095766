import styled from 'styled-components';
import breakpoints from '../../utilities/breakpoints';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;

    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

Grid.One = styled.div`
    margin: 20px;
`;

export default Grid;
