import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getPartners } from '../../../actions';
import Pagination from '../../shared/Pagination';
import { Title } from '../../ui/Table';
import usePaging from '../../utilities/usePaging';
import PartnerTable from './PartnerTable';

const Partners = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [result, setResult] = useState(null);
    const paging = usePaging(1, 5);

    useEffect(() => {
        dispatch(getPartners(id, paging)).then(setResult);
    }, [dispatch, id, paging, setResult]);

    return (
        <>
            <Title>参与者（{result?.list.length}）</Title>
            <PartnerTable items={result?.list} />
            <Pagination paging={paging} records={result?.total || 0} />
        </>
    );
};

export default Partners;
